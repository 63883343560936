import { useRef } from 'react'
import { 
	IconButton, 
	Icon, 
	Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
	useDisclosure
} from '@chakra-ui/react'
import { IoMdMenu } from 'react-icons/io'
import { useTheme } from 'theme'

export const DrawerMenu = ({ placement, children }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const btnRef = useRef()
	const { isHighContrast } = useTheme()

	const contentBg = isHighContrast ? "#353535" : 'white'
	const buttonColor = isHighContrast ? "#F4F4F4" : 'blue.300'

	return (
		<>
			<IconButton 
				variant='ghost' 
				_hover={{ bg: 'none' }} 
				icon={<Icon as={IoMdMenu} />} 
				color={buttonColor}
				cursor='pointer'
				size="lg"
				ref={btnRef}  
				onClick={onOpen}
				aria-label='Abrir menu de navegação'
			/>

			<Drawer
				isOpen={isOpen}
				placement={placement}
				onClose={onClose}
				finalFocusRef={btnRef}
				
			>
				<DrawerOverlay />
				<DrawerContent background={contentBg}>
					<DrawerCloseButton />
					<DrawerHeader />

					<DrawerBody>
						{children}
					</DrawerBody>
					
				</DrawerContent>
			</Drawer>
		</>
	)
}