/*
  Button style guide base on UX figma project (link)
*/ 

export const Button = {
  baseStyle: {
    fontWeight: 'normal',
    fontsize: 'sm',
		padding: 0,
  },
  variants: {
    default: {
      padding: 4,
      color: 'white',
      bgColor: '#3f9e6e',
      borderRadius: '3xl',
    },
		contrast: {
			bg: 'white',
			color: 'black'
		},
    nav: {
      px: 4,
      py: 2,
      color: 'white',
      _hover: {
        bgColor: '#3f9e6e'
      }
    },
		home: {
			bgColor: "white",
			_hover: {
				bgColor: "#3f9e6e",
			},
		},
		gradient: {
			border: '3px solid',
			borderColor: 'transparent',
			borderRadius: '3xl',
			background: `linear-gradient(#fff, #fff) padding-box,
			linear-gradient(45deg, #3F9E6E, #000055) border-box`,
			'> *': {
				background: `linear-gradient(45deg, #3F9E6E, #000055)`,
				backgroundClip: 'text',
				textFillColor: 'transparent',
			},
			_hover: {
				background: `linear-gradient(#65B18B, #65B18B) padding-box,
				linear-gradient(45deg, #3F9E6E, #000055) border-box`,
				'> *': {
					background: 'white',
					backgroundClip: 'text',
				}
			}
		}
  }
}
