import jwtDecode from 'jwt-decode'

const access_key = 'access'
const refresh_key = 'refresh'

export const Tokens = (() => {
	const set = (tokens) => {
		const { access, refresh } = tokens

		if (access) {
			const accessTokenString = JSON.stringify(tokens.access)
			sessionStorage.setItem(access_key, accessTokenString)
		}

		if (refresh) {
			const refreshTokenString = JSON.stringify(tokens.refresh)
			sessionStorage.setItem(refresh_key, refreshTokenString)
		}
	}

	const get = () => {
		const tokens = getLocalTokens()

		return {
			access_token: tokens?.access,
			refresh_token: tokens?.refresh
		}
	}

	const clear = (keepRefresh = false) => {
		if (keepRefresh) {
			sessionStorage.removeItem(access_key)
		} else {
			sessionStorage.removeItem(access_key)
			sessionStorage.removeItem(refresh_key)
		}
	}

	const getLocalTokens = () => {
		const access = sessionStorage.getItem(access_key)
		const refresh = sessionStorage.getItem(refresh_key)

		if (access && refresh) {
			return {
				access: JSON.parse(access),
				refresh: JSON.parse(refresh)
			}
		}

		return null
	}

	const decode = (token) => {
		return jwtDecode(token)
	}

	const getExpirationDate = (exp) => new Date(exp * 1000)

	const isValid = (exp) => new Date() < getExpirationDate(exp)

	return {
		set,
		get,
		clear,
		decode,
		isValid
	}
})()