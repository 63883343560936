import fetch from 'config/api'

export const authenticate = async code => {
	const { protocol, host } = window.location
	const redirect_uri = `${protocol}//${host}/home`

	return fetch({
		url: '/govbr/',
		method: 'GET',
		params: {
			redirect_uri,
			code
		}
	}).then((res) => {
		return res.data
	}).catch((err) => console.error('auth error', err))
}