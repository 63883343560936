import { Tokens } from 'config/tokens'

export const useHasSession = () => {
	const { access_token, refresh_token } = Tokens.get()

	if (access_token && refresh_token) {
		const decodedAccess = Tokens.decode(access_token)
		const decodedRefresh = Tokens.decode(refresh_token)

		const validAccess = Tokens.isValid(decodedAccess.exp)
		const validRefresh = Tokens.isValid(decodedRefresh.exp)

		return validAccess || validRefresh
	}

	return false
}