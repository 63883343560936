import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
	MenuDivider,
	Text,
	Button,
	Icon,
	Stack,
} from '@chakra-ui/react'
import { BiCaretDown, BiCaretUp } from 'react-icons/bi'
import { useSessionHandler } from 'modules/common/hooks'
import { useTheme } from 'theme'

export const UserActionsButton = () => {
	const { getCurrentUser, closeSession } = useSessionHandler()
	const user = getCurrentUser()
	const { isHighContrast } = useTheme()

	const menuBg = isHighContrast ? '#353535' : '#fff'
	const menuItemHover = isHighContrast ? { bg: 'none' } : { bg: 'gray.200' }

	const handleLogout = () => {
		closeSession()
	}

	const handleClick = () => {
		alert('Essa funcionalidade ainda está sendo implementada')
	}

	return (
		<Menu>
			{({ isOpen, onOpen }) => (
				<>
					<MenuButton 
						as={Button} 
						isActive={isOpen} 
						rightIcon={
							isOpen ? <Icon as={BiCaretUp} color="#3F9E6E" /> 
							: <Icon as={BiCaretDown} color="#3F9E6E" />
						}
						bg="white"
						border="1px solid gray.200"
						borderRadius="lg"
						onMouseOver={onOpen}
					>
						<Stack direction="row" spacing={1}>
							<Text color="blue.900">Olá,</Text>
							<Text color="blue.900" fontWeight="semibold">{user?.nome}</Text>
						</Stack>
					</MenuButton>
					<MenuList bg={menuBg}>
						<MenuItem _focus={{ bg: 'none' }} onClick={handleClick} _hover={menuItemHover}>Perfil</MenuItem>
						<MenuDivider />
						<MenuItem onClick={handleClick} _hover={menuItemHover}>Alterar senha</MenuItem>
						<MenuDivider />
						<MenuItem onClick={handleClick} _hover={menuItemHover}>Termo de adesão</MenuItem>
						<MenuDivider />
						<MenuItem onClick={handleLogout} _hover={menuItemHover}>Sair</MenuItem>
					</MenuList>
				</>
			)}
		</Menu>
	)
}