import { useContext } from "react";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";

import Router from "./routes";
import { Fonts } from "theme/fonts";
import { ThemeContext } from "theme";
import { SizeProvider } from "Context/letterSizeHook";

const App = () => {
	const { theme } = useContext(ThemeContext);

	return (
		<ChakraProvider theme={theme}>
			<SizeProvider>
				<ColorModeScript />
				<Fonts />
				<Router />
			</SizeProvider>
		</ChakraProvider>
	);
};

export default App;
