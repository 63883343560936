import React from "react";
import { Stack, Box, Text, Center } from "@chakra-ui/react";
import { TextGradient } from "modules/common/components";

import objetivos from "../../static/objetivos";
import { FullPageLayout } from "modules/common/components/FullPageLayout";
import { useSize } from "Context/letterSizeHook";

export const AboutConectaPNE = React.forwardRef((props, ref) => {
	const { getFont } = useSize();
	return (
		<FullPageLayout bg="gray.180" shadow="md">
			<Stack ref={ref} py={8}>
				<TextGradient
					fromColor="esmeralda.100"
					toColor="marinho.100"
					fontSize={getFont("3xl")}
					fontWeight="bold"
				>
					O que é a plataforma Conecta PNE?
				</TextGradient>
				<Text
					fontSize={getFont("xl")}
					aria-label="Conecta PNE é uma plataforma do"
				>
					Conecta PNE é uma plataforma do {' '}
					<Text
						as="span"
						fontWeight="semibold"
						aria-label="Ministério da Educação"
					>
						Ministério da Educação
					</Text>
					, que tem como objetivos:
				</Text>
				{objetivos.map((objetivo) => (
					<Center key={objetivo.title}>
						<Box
							w="xl"
							h="fit-content"
							mt={6}
							pl={12}
							py={4}
							pr={8}
							borderRadius="3xl"
							layerStyle="gradientBorderWhiteBg"
						>
							<Stack spacing={12} align="center" direction={["column", "row"]}>
								<TextGradient
									fromColor="esmeralda.100"
									toColor="marinho.100"
									fontSize={getFont("4xl")}
									fontWeight="bold"
								>
									{objetivo.title}
								</TextGradient>
								<Text fontSize={getFont("lg")} arial-label={objetivo.text}>
									{objetivo.text}
								</Text>
							</Stack>
						</Box>
					</Center>
				))}
			</Stack>
		</FullPageLayout>
	);
});
