const metas = [
	{
		title: "01",
		text: "Universalizar, até 2016, a educação infantil na pré-escola para as crianças de 4 (quatro) a 5 (cinco) anos de idade e ampliar a oferta de educação infantil em creches de forma a atender, no mínimo, 50% (cinquenta por cento) das crianças de até 3 (três) anos até o final da vigência deste PNE.",
	},
	{
		title: "02",
		text: "Universalizar o ensino fundamental de 9 (nove) anos para toda a população de 6 (seis) a 14 (quatorze) anos e garantir que pelo menos 95% (noventa e cinco por cento) dos alunos concluam essa etapa na idade recomendada, até o último ano de vigência deste PNE.",
	},
	{
		title: "03",
		text: "Universalizar, até 2016, o atendimento escolar para toda a população de 15 (quinze) a 17 (dezessete) anos e elevar, até o final do período de vigência deste PNE, a taxa líquida de matrículas no ensino médio para 85% (oitenta e cinco por cento).",
	},
	{
		title: "04",
		text: "Universalizar, para a população de 4 (quatro) a 17 (dezessete) anos com deficiência, transtornos globais do desenvolvimento e altas habilidades ou superdotação, o acesso à educação básica e ao atendimento educacional especializado, preferencialmente na rede regular de ensino, com a garantia de sistema educacional inclusivo, de salas de recursos multifuncionais, classes, escolas ou serviços especializados, públicos ou conveniados.",
	},
	{
		title: "05",
		text: "Alfabetizar todas as crianças, no máximo, até o final do 3o (terceiro) ano do ensino fundamental.",
	},
	{
		title: "06",
		text: "Oferecer educação em tempo integral em, no mínimo, 50% (cinquenta por cento) das escolas públicas, de forma a atender, pelo menos, 25% (vinte e cinco por cento) dos (as) alunos (as) da educação básica.",
	},
	{
		title: "07",
		text: "Fomentar a qualidade da educação básica em todas as etapas e modalidades, com melhoria do fluxo escolar e da aprendizagem de modo a atingir as seguintes médias nacionais para o Ideb.",
	},
	{
		title: "08",
		text: "Elevar a escolaridade média da população de 18 (dezoito) a 29 (vinte e nove) anos, de modo a alcançar, no mínimo, 12 (doze) anos de estudo no último ano de vigência deste Plano, para as populações do campo, da região de menor escolaridade no País e dos 25% (vinte e cinco por cento) mais pobres, e igualar a escolaridade média entre negros e não negros declarados à Fundação Instituto Brasileiro de Geografia e Estatística - IBGE.",
	},
	{
		title: "09",
		text: "Elevar a taxa de alfabetização da população com 15 (quinze) anos ou mais para 93,5% (noventa e três inteiros e cinco décimos por cento) até 2015 e, até o final da vigência deste PNE, erradicar o analfabetismo absoluto e reduzir em 50% (cinquenta por cento) a taxa de analfabetismo funcional.",
	},
	{
		title: "10",
		text: "Oferecer, no mínimo, 25% (vinte e cinco por cento) das matrículas de educação de jovens e adultos, nos ensinos fundamental e médio, na forma integrada à educação profissional.",
	},
	{
		title: "11",
		text: "Triplicar as matrículas da educação profissional técnica de nível médio, assegurando a qualidade da oferta e pelo menos 50% (cinquenta por cento) da expansão no segmento público.",
	},
	{
		title: "12",
		text: "Elevar a taxa bruta de matrícula na educação superior para 50% (cinquenta por cento) e a taxa líquida para 33% (trinta e três por cento) da população de 18 (dezoito) a 24 (vinte e quatro) anos, assegurada a qualidade da oferta e expansão para, pelo menos, 40% (quarenta por cento) das novas matrículas, no segmento público.",
	},
	{
		title: "13",
		text: "Elevar a qualidade da educação superior e ampliar a proporção de mestres e doutores do corpo docente em efetivo exercício no conjunto do sistema de educação superior para 75% (setenta e cinco por cento), sendo, do total, no mínimo, 35% (trinta e cinco por cento) doutores.",
	},
	{
		title: "14",
		text: "Elevar gradualmente o número de matrículas na pós-graduação de modo a atingir a titulação anual de 60.000 (sessenta mil) mestres e 25.000 (vinte e cinco mil) doutores.",
	},
	{
		title: "15",
		text: "Garantir, em regime de colaboração entre a União, os Estados, o Distrito Federal e os Municípios, no prazo de 1 (um) ano de vigência deste PNE, política nacional de formação dos profissionais da educação de que tratam os incisos I, II e III do caputdo art. 61 da Lei n 9.394, de 20 de dezembro de 1996, assegurado que todos os professores e as professoras da educação básica possuam formação específica de nível superior, obtida em curso de licenciatura na área de conhecimento em que atuam.",
	},
	{
		title: "16",
		text: "Formar, em nível de pós-graduação, 50% (cinquenta por cento) dos professores da educação básica, até o último ano de vigência deste PNE, e garantir a todos (as) os (as) profissionais da educação básica formação continuada em sua área de atuação, considerando as necessidades, demandas e contextualizações dos sistemas de ensino.",
	},
	{
		title: "17",
		text: "Valorizar os (as) profissionais do magistério das redes públicas de educação básica de forma a equiparar seu rendimento médio ao dos (as) demais profissionais com escolaridade equivalente, até o final do sexto ano de vigência deste PNE.",
	},
	{
		title: "18",
		text: "Assegurar, no prazo de 2 (dois) anos, a existência de planos de Carreira para os (as) profissionais da educação básica e superior pública de todos os sistemas de ensino e, para o plano de Carreira dos (as) profissionais da educação básica pública, tomar como referência o piso salarial nacional profissional, definido em lei federal, nos termos do inciso VIII do art. 206 da Constituição Federal.",
	},
	{
		title: "19",
		text: "Assegurar condições, no prazo de 2 (dois) anos, para a efetivação da gestão democrática da educação, associada a critérios técnicos de mérito e desempenho e à consulta pública à comunidade escolar, no âmbito das escolas públicas, prevendo recursos e apoio técnico da União para tanto.",
	},
	{
		title: "20",
		text: "Ampliar o investimento público em educação pública de forma a atingir, no mínimo, o patamar de 7% (sete por cento) do Produto Interno Bruto - PIB do País no 5o (quinto) ano de vigência desta Lei e, no mínimo, o equivalente a 10% (dez por cento) do PIB ao final do decênio.",
	},
];

export default metas
