import { useNavigate } from 'react-router-dom'
import { Tokens } from "config/tokens"

const key = 'user'

export const useSessionHandler = () => {
	const navigate = useNavigate()

	const setCurrentUser = (user) => {
		const userString = JSON.stringify(user)
		sessionStorage.setItem(key, userString)
	}

	const getCurrentUser = () => {
		const user = sessionStorage.getItem(key)
		if (user) return JSON.parse(user)
	}

	const removeCurrentUser = () => {
		sessionStorage.removeItem(key)
	}

	const closeSession = () => {
		Tokens.clear()
		removeCurrentUser()
		navigate('/', { replace: true })
	}

	return {
		setCurrentUser,
		getCurrentUser,
		removeCurrentUser,
		closeSession,
	}
}