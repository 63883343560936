import { extendTheme } from '@chakra-ui/react'
import { colors } from '../colors'
import { Button, Input, Text, Link } from './components'

const fonts = {
  heading: 'Roboto, sans-serif',
  body: 'Roboto, sans-serif,'
}

const layerStyles = {
	gradientBorder: {
		border: '3px solid',
		borderColor: 'transparent',
		background: `linear-gradient(#F5F5F5, #F5F5F5) padding-box,
		linear-gradient(45deg, #3F9E6E, #000055) border-box`,
	},
	gradientBorderWhiteBg: {
		border: '3px solid',
		borderColor: 'transparent',
		background: `linear-gradient(#fff, #fff) padding-box,
		linear-gradient(45deg, #3F9E6E, #000055) border-box`,
	}
}

export const defaultTheme = extendTheme({
  colors,
  fonts,
	layerStyles,
  components: {
    Button,
    Input,
		Text,
		Link
  }
})