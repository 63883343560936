import { Box, Container, useBreakpointValue } from "@chakra-ui/react"

export const FullPageLayout = ({ children, ...boxProps }) => {
	const mobileScreen = useBreakpointValue({ base: true, xl: false })

	return (
		<Box px={mobileScreen ? 8 : 0} py={0} {...boxProps}>
			<Container maxW='1170px' p={0}>
				{children}
			</Container>
		</Box>
	)
}