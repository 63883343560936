import { Link } from "react-router-dom"
import { Button, Stack } from "@chakra-ui/react"

import { ResponsiveLogo, FullPageLayout } from ".."
import { useHasSession } from "modules/common/hooks"
import { UserActionsButton } from "../UserActionsButton"


export const Navbar = () => {
	const NavButtons = () => {
		const isAuthenticated = useHasSession()

		// TRECHO DE CODIGO COMENTADO
		// POIS SERA UTILIZADO EM BREVE

		// const handleLoginGovbr = async () => {
		// 	const url = await govbrAuth()
		// 	window.location.href = url
		// }

		if (!isAuthenticated) {
			return (
				<Stack direction={{ base: "column", md: "row" }} align="center">
					<Link to="/dash">
						<Button variant="default">Apoio ao monitoramento</Button>
					</Link>

{/* 	TRECHO DE CODIGO COMENTADO
			POIS SERA UTILIZADO EM BREVE
					
					<Button
						variant="nav"
						bg="#3F9E6E"
						aria-label="Login com gov.br"
						onClick={handleLoginGovbr}
						borderRadius='lg'
					>
						<Stack direction="row" spacing={1}>
							<Text>Login com</Text>
							<Text fontWeight="bold">gov.br</Text>
						</Stack>
					</Button> */}
				</Stack>
			);
		}

		return (
			<Stack direction={{ base: "column", md: "column", lg: "row" }} align="center">
				<Button
					variant="default"
					bg="esmeralda.80"
					aria-label="Cadastro do plano"
				>
					Cadastro do plano
				</Button>
				<Button variant="default" bg="esmeralda.80" aria-label="Apoio ao monitoramento">
					Apoio ao monitoramento
				</Button>
				<Button variant="default" bg="esmeralda.80" aria-label="Relatório de avaliação">
					Relatório de avaliação
				</Button>
				<UserActionsButton />
			</Stack>
		)
	}

	return (
		<FullPageLayout bg="gray.180" shadow="md">
			<Stack
				py={6}
				spacing={6}
				direction={{ base: "column", sm: "row" }}
				alignItems={{ base: "center", sm: "end" }}
				justifyContent="space-between"
			>
				<ResponsiveLogo />

				<NavButtons />
			</Stack>
		</FullPageLayout>
	);
};
