import axios from 'axios'
import { Tokens } from '../tokens'
import { addAuthentication } from '../helpers/add-authentication'

const baseURL = process.env.REACT_APP_API_URL_DEV

const api = axios.create({
	baseURL,
	headers: {
		'Content-Type': 'application/json'
	},
})

api.interceptors.request.use(async (request) => {
	const withoutAuth = !request.url?.includes('auth')
	const { isAccessValid, isRefreshValid, refresh_token } = validateTokens()

	if (!isAccessValid && isRefreshValid && refresh_token && withoutAuth) {
		await refreshToken(refresh_token, isRefreshValid)
	}

	return addAuthentication(request)
})

const validateTokens = () => {
	const { get, decode, isValid } = Tokens
	const { access_token, refresh_token } = get()
	const result = { 
		access_token, 
		refresh_token, 
		isAccessValid: false, 
		isRefreshValid: false 
	}

	if (access_token && refresh_token) {
		const decodedAccess = decode(access_token)
		const decodedRefresh = decode(refresh_token)
		result.isAccessValid = isValid(decodedAccess.exp)
		result.isRefreshValid = isValid(decodedRefresh.exp)
	}

	return result
}

const refreshToken = async (refreshToken, keepRefresh) => {
	const refreshed = await axios
		.request({
			url: `${baseURL}/usuario/refresh/`,
			method: 'POST',
			data: { refresh: refreshToken }
		})
		.then((res) => {
			return res.data
		})

	Tokens.clear(keepRefresh)
	Tokens.set(refreshed)
	return refreshed
}

const fetch = (requestConfig) => api.request(requestConfig)

export default fetch