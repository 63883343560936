import { useContext } from "react"
import { Text } from "@chakra-ui/react"
import { ThemeContext } from "theme"

export const TextGradient = ({
	children,
	fromColor,
	toColor,
	...textProps
}) => {
	const { isHighContrast } = useContext(ThemeContext)

	if (isHighContrast) {
		return (
			<Text color="white" {...textProps}>
				{children}
			</Text>
		)
	}
	
	return (
		<Text
			bgGradient={`linear(to-r, ${fromColor}, ${toColor})`}
			bgClip="text"
			{...textProps}
			aria-label={children}
		>
			{children}
		</Text>
	);
};
