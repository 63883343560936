/*
  Input style guide base on UX figma project (link)
*/

export const Input = {
  baseStyle: {
    fontWeight: 'semibold',
    fontSize: '14px',
    color: '#3F9E6E',
    textColor: '#6C757D',
  },
}
