import React from "react";
import { TextGradient } from "modules/common/components";
import {
	Stack,
	Button,
	Box,
	Center,
	Link,
	useBreakpointValue,
} from "@chakra-ui/react";
import metas from "../../static/metas.js";
import { FullPageLayout } from "modules/common/components/FullPageLayout/index.jsx";
import ReadMore from "modules/common/components/ReadMore/index.jsx";
import { useSize } from "Context/letterSizeHook/index.jsx";

export const PneGoals = React.forwardRef((props, ref) => {
	const { getFont } = useSize();
	const variant = useBreakpointValue({
		base: "lg",
		sm: "xl",
		md: "2xl",
	});
	return (
		<FullPageLayout>
			<Box ref={ref} py={8}>
				<TextGradient
					fromColor="esmeralda.100"
					toColor="marinho.100"
					fontSize={getFont("3xl")}
					fontWeight="bold"
				>
					As 20 metas do PNE
				</TextGradient>
				<Stack mt={6} spacing={4}>
					<ReadMore metas={metas}></ReadMore>
				</Stack>
				<Center w="full">
					<Button
						w="lg"
						h={14}
						mt={12}
						as={Link}
						isExternal
						fontSize={variant}
						aria-label="LEI 13.005/2014, que regulamenta o PNE"
						variant="default"
						borderRadius="lg"
						_hover={{ textDecoration: "none" }}
						href="https://www2.camara.leg.br/legin/fed/lei/2014/lei-13005-25-junho-2014-778970-publicacaooriginal-144468-pl.html"
					>
						LEI 13.005/2014, que regulamenta o PNE
					</Button>
				</Center>
			</Box>
		</FullPageLayout>
	);
});
