import React from "react";
import { Box, Image, Stack, Text } from "@chakra-ui/react";
import { TextGradient } from "modules/common/components";
import { FullPageLayout } from "modules/common/components/FullPageLayout";

import Ilustration from "modules/common/assets/images/ilustra.svg";
import { useSize } from "Context/letterSizeHook";

export const AboutPNE = React.forwardRef((props, ref) => {
	const { getFont } = useSize();
	return (
		<FullPageLayout bg="gray.180" shadow="md">
			<Box ref={ref} py={8}>
				<TextGradient
					fromColor="esmeralda.100"
					toColor="marinho.100"
					fontSize={getFont("3xl")}
					fontWeight="bold"
				>
					O que é o PNE?
				</TextGradient>
				<Stack direction={{ base: "column", lg: "row" }} align="center">
					<Text
						fontSize={getFont("lg")}
						aria-label="O Plano Nacional de Educação (PNE) determina 20 metas"
					>
						O Plano Nacional de Educação (PNE) determina 20 metas para a
						educação brasileira, a serem cumpridas ao longo de dez anos, a
						partir de junho de 2014. Todos os entes federados (municípios,
						estados e distrito federal) devem possuir um plano subnacional de
						educação, alinhado ao PNE, porém respeitando as características
						específicas do seu contexto. Os entes federados devem continuamente
						monitorar e avaliar seus planos, e atuar em regime de colaboração
						(entes subnacionais e União), visando ao alcance das metas e à
						implementação das estratégias do PNE.
					</Text>
					<Image
						src={Ilustration}
						alt="Ilustração de um boneco sentado em frente ao computador e um quadro de atividades"
					/>
				</Stack>
			</Box>
		</FullPageLayout>
	);
});
