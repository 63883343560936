import { Stack, Link } from "@chakra-ui/react"
import { Navbar, Govbar, Footer } from "modules/common/components"
import { CardList } from "./components/card"

const HomePage = () => {
	return (
		<>
			<Link href="#maincontent" aria-label="Link direto para o texto"></Link>
			<Govbar />
			<Navbar />
			<Stack>
				<CardList routeMonitoration="/dash" />
				<Footer />
			</Stack>
		</>
	)
}

export default HomePage
