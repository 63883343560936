import { useContext } from "react"
import { ThemeContext } from "theme"
import { Box, Stack, Image } from "@chakra-ui/react"
import { TextGradient } from "modules/common/components"

import { FullPageLayout } from "../FullPageLayout"
import { useSize } from "Context/letterSizeHook"

import MecLogo from "modules/common/assets/images/mec-logo.svg"
import UfalLogo from "modules/common/assets/images/ufal-logo.svg"
import UfrpeLogo from "modules/common/assets/images/ufrpe-logo.svg"
import NeesLogo from "modules/common/assets/images/nees-logo.svg"
import AiboxLogo from "modules/common/assets/images/aibox-logo.svg"

import MecWhiteLogo from 'modules/common/assets/high-contrast-theme-images/mec-white.svg'
import UfalWhiteLogo from 'modules/common/assets/high-contrast-theme-images/ufal-white.svg'
import UfrpeWhiteLogo from 'modules/common/assets/high-contrast-theme-images/ufrpe-white.svg'
import NeesWhiteLogo from 'modules/common/assets/high-contrast-theme-images/NEES-white.svg'

export const Footer = () => {
	const { getFont } = useSize()
	const { isHighContrast } = useContext(ThemeContext)

	const logos = {
		mecLogo: isHighContrast ? MecWhiteLogo : MecLogo,
		ufalLogo: isHighContrast ? UfalWhiteLogo : UfalLogo,
		ufrpeLogo: isHighContrast ? UfrpeWhiteLogo : UfrpeLogo,
		neesLogo: isHighContrast ? NeesWhiteLogo : NeesLogo,
	}

	return (
		<FullPageLayout bg="gray.180">
			<Stack
				py={6}
				spacing={{ base: 22, sm: 32, md: 56, lg: 44 }}
				direction={{ base: "column", sm: "row" }}
				justify={{ base: "start", lg: "space-between" }}
			>
				<Box alignSelf={{ base: "center", sm: "start" }}>
					<TextGradient
						fromColor="esmeralda.100"
						toColor="marinho.100"
						fontSize={getFont("md")}
						fontWeight="medium"
						textAlign={{ base: "center", sm: "start" }}
					>
						Financiamento
					</TextGradient>
					<Image mt={8} src={logos.mecLogo} alt="Ministério da Educação" />
				</Box>
				<Box alignSelf={{ base: "center", sm: "start" }}>
					<TextGradient
						fromColor="esmeralda.100"
						toColor="marinho.100"
						fontSize={getFont("md")}
						fontWeight="medium"
						textAlign={{ base: "center", sm: "start" }}
					>
						Realização
					</TextGradient>
					<Stack
						mt={6}
						direction={{ base: "column", lg: "row" }}
						align={{ base: "start", lg: "center" }}
						spacing={6}
					>
						<Image
							src={logos.ufalLogo}
							alt="Logotipo da Universidade Federal de Alagoas"
						/>
						<Image
							src={logos.ufrpeLogo}
							alt="Logotipo da Universidade Federal Rural de Pernambuco"
						/>
						<Image
							src={logos.neesLogo}
							alt="Logotipo do Núcleo de Excelência em Tecnologias Sociais"
						/>
						<Image src={AiboxLogo} alt="Logotipo da Aibox Lab" />
					</Stack>
				</Box>
			</Stack>
		</FullPageLayout>
	);
};
