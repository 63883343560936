import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PrivateRoute } from "./private-route"

import LandingPage from "modules/landing-page"
import LoginRedirect from "modules/login"
import Dash from "modules/dash"
import Home from "modules/home"

const Router = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<LandingPage />} />
				<Route path="/login" element={<LoginRedirect />} />
				<Route path="/dash" element={<Dash />} />
				<Route 
					path="/home" 
					element={
						<PrivateRoute>
							<Home />
						</PrivateRoute>
					} 
				/>
			</Routes>
		</BrowserRouter>
	)
}

export default Router
