import { Button, Center, Stack, Text } from "@chakra-ui/react";
import { useSize } from "Context/letterSizeHook";
import { FullPageLayout, TextGradient } from "modules/common/components";
import { Link } from "react-router-dom";

export const Card = ({ text, title, buttonText, route }) => {
	const { getFont } = useSize();

	const onClick = () => {
		alert('Esta funcionalidade ainda está sendo desenvolvida :)')
	}

	return (
		<Stack
			h="360px"
			borderRadius="3xl"
			layerStyle="gradientBorder"
			p={2}
			alignItems="center"
			w="xs"
			justifyContent="space-between"
		>
			<TextGradient
				fromColor="esmeralda.100"
				toColor="marinho.100"
				fontSize={getFont("3xl")}
				fontWeight="medium"
				textAlign="center"
				aria-label={title}
			>
				{title}
			</TextGradient>
			<Text fontSize={getFont("xl")} textAlign="center" aria-label={text}>
				{text}
			</Text>
			{route ? (
				<Link to={route}>
					<Button
						variant="nav"
						bg="#3F9E6E"
						w="2xs"
						h={10}
						aria-label={buttonText}
						fontSize={getFont("xl")}
					>
						{buttonText}
					</Button>
				</Link>
			) : (
				<Button
					variant="nav"
					bg="#3F9E6E"
					w="2xs"
					h={10}
					aria-label={buttonText}
					fontSize={getFont("xl")}
					onClick={onClick}
				>
					{buttonText}
				</Button>
			)}
		</Stack>
	);
};

export const CardList = ({ routeSingIn, routeMonitoration, routeAvaliation }) => {
	return (
		<Center>
			<FullPageLayout>
				<Stack py={16} spacing={4}>
					<Stack
						direction={["column", "column", "row"]}
						alignItems="center"
						spacing={7}
					>
						<Card
							route={routeSingIn}
							title={"Cadastro do Plano"}
							text={
								"Você, gestor(a) público(a),  pode inserir os dados do plano  de educação de seu estado ou município neste módulo. Nele pode-se adicionar as metas, estratégias e ações."
							}
							buttonText={"Ir para o cadastro"}
						></Card>
						<Card
							route={routeMonitoration}
							title={"Apoio ao monitoramento"}
							text={
								"Este módulo provê uma análise da evolução das metas do plano de educação (município ou estado) para apoiar a tomada de decisão."
							}
							buttonText={"Ir para o monitoramento"}
						></Card>
						<Card
							route={routeAvaliation}
							title={"Relatório de avaliação"}
							text={
								"Neste módulo você terá apoio para construir o relatório de avaliação do seu município ou estado, a partir dos dados do monitoramento das metas."
							}
							buttonText={"Ir para a avaliação"}
						></Card>
					</Stack>
				</Stack>
			</FullPageLayout>
		</Center>
	);
};
