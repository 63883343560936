import { Tokens } from '../tokens'

export const addAuthentication = (config) => {
	const { access_token } = Tokens.get()

	if (access_token) {
		config.headers = {
			...config.headers,
			Authorization: `Bearer ${access_token}`
		}
	}

	return config
}