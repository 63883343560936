export const Text = {
	baseStyle: {
		lineHeight: '10'
	},
	variants: {
		breakLines: {
			whiteSpace: 'pre-line',
			hyphens: 'auto',
			wordBreak: 'break-word',
			lineHeight: '5'
		},
	}
}