import { Navigate } from 'react-router-dom'
import { Tokens } from 'config/tokens'
import { useHasSession } from 'modules/common/hooks'

export const PrivateRoute = ({ children }) => {
	const isAuthenticated = useHasSession()

	if (isAuthenticated) {
		return <>{children}</>
	}

	Tokens.clear()
	return <Navigate to="/" />
}