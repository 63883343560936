export const sizeLetter = {
	xs: ["xs", 1],
	sm: ["sm", 2],
	md: ["md", 3],
	lg: ["lg", 4],
	xl: ["xl", 5],
	"2xl": ["2xl", 6],
	"3xl": ["3xl", 7],
	"4xl": ["4xl", 8],
	"5xl": ["5xl", 9],
	"6xl": ["6xl", 10],
	"7xl": ["7xl", 11],
	"8xl": ["8xl", 12],
	"9xl": ["9xl", 13],
};
