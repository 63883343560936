import { Stack } from "@chakra-ui/react";
import { Footer, Govbar, Navbar } from "modules/common/components";

const Dash = () => {
	return (
		<Stack w="100%" h="100%">
			<Govbar />
			<Navbar />
			<iframe
				src="https://conectapne.nees.ufal.br/bi/"
				title="Streamlit Dashboard"
				allowFullScreen={true}
				height="2700"
			></iframe>
			<Footer />
		</Stack>
	);
};
export default Dash;
