import { extendTheme } from '@chakra-ui/react'
import { Button, Input, Text, Link } from './components'

const fonts = {
  heading: 'Roboto, sans-serif',
  body: 'Roboto, sans-serif,'
}

const layerStyles = {
	gradientBorder: {
		border: '3px solid',
		borderColor: '#F4F4F4',
		background: 'none',
	},
	gradientBorderWhiteBg: {
		border: '3px solid',
		borderColor: '#F4F4F4',
		background: 'none',
	}
}

export const highContrastTheme = extendTheme({
	fonts,
	layerStyles,
	styles: {
		global: {
			body: {
				bg: '#353535',
				color: '#F4F4F4'
			}
		}
	},
	components: {
		Button, 
		Input, 
		Text,
		Link
	}
})