export const colors = {
  canario: {
    60: "#FFEF9F",
    80: "#FFE97F",
    100: "#FFE45F"
  },
  marinho: {
    60: "#666699",
    80: "#333377",
    100: "#000055"
  },
  esmeralda: {
    60: "#8CC5A8",
    80: "#65B18B",
    100: "#3F9E6E"
  },
  gray: {
    80: "#F8F9FA",
    180: "#F5F5F5",
		200: "#E2E8F0",
    900: "#353535"
  },
	blue: {
		300: "#1351B4",
		900: "#2D3748"
	}
}