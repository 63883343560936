import React, { useContext } from "react"
import { Box, Text, Stack, Image, useBreakpointValue } from "@chakra-ui/react"
import { ThemeContext } from "theme"
import { TextGradient } from "modules/common/components"
import { FullPageLayout } from "modules/common/components/FullPageLayout"
import { useSize } from "Context/letterSizeHook"
import Seta from "modules/common/assets/images/seta.svg"
import SetaBranca from "modules/common/assets/high-contrast-theme-images/seta-white.svg"

const Step = ({ title, children, ...boxProps }) => {
	const { getFont } = useSize()

	return (
		<Box
			w="3xl"
			h="24"
			px={14}
			py={4}
			maxW="full"
			display="flex"
			color="white"
			bg="esmeralda.80"
			borderRadius="2xl"
			{...boxProps}
		>
			<Stack spacing={12} direction="row" align="center">
				<Text fontSize={getFont("4xl")} fontWeight="semibold">
					{title}
				</Text>
				<Text
					fontSize={["18", getFont("xl"), getFont("2xl")]}
					fontWeight="normal"
					lineHeight={{ base: 6, sm: 10 }}
				>
					{children}
				</Text>
			</Stack>
		</Box>
	);
};

const ResponsiveSteps = () => {
	const mobileScreen = useBreakpointValue({ base: true, lg: false });
	const { isHighContrast } = useContext(ThemeContext)

	const seta = isHighContrast ? SetaBranca : Seta

	if (mobileScreen) {
		return (
			<Stack>
				<Box w="full" mt={5}>
					<Step title="1">
						Realize seu login e/ou cadastro através do sistema gov.br;
					</Step>

					<Step title="2" mt={8}>
						Siga o passo a passo indicado para o seu perfil;
					</Step>

					<Step title="3" mt={8}>
						Salve relatórios e insights conforme suas necessidades.
					</Step>
				</Box>
			</Stack>
		);
	}

	return (
		<Stack>
			<Box w="full" mt={5}>
				<Step title="1">
					Realize seu login e/ou cadastro através do sistema gov.br;
				</Step>
				<Box mt={-20} ml={3} pos="absolute">
					<Image src={seta} alt="Seta azul direcionando para próximo passo" />
				</Box>
				<Step title="2" mt={8} ml={44}>
					Siga o passo a passo indicado para o seu perfil;
				</Step>
				<Box mt={-20} ml={48} pos="absolute">
					<Image src={seta} alt="Seta azul direcionando para próximo passo" />
				</Box>
				<Step title="3" mt={8} ml="352px">
					Salve relatórios e insights conforme suas necessidades.
				</Step>
			</Box>
		</Stack>
	);
};

export const StepByStep = React.forwardRef((props, ref) => {
	const { getFont } = useSize();
	return (
		<FullPageLayout>
			<Box ref={ref} py={8}>
				<TextGradient
					fromColor="esmeralda.100"
					toColor="marinho.100"
					fontSize={getFont("3xl")}
					fontWeight="bold"
				>
					Entenda o passo a passo
				</TextGradient>

				<ResponsiveSteps />

				<Box
					mt={10}
					px={8}
					py={6}
					borderRadius={getFont("2xl")}
					layerStyle="gradientBorder"
				>
					<Text
						fontSize={getFont("xl")}
						aria-label="também é possível cadastrar os planos de educação e realizar as
						etapas necessárias de entrega ao MEC através da plataforma."
					>
						<Text
							as="span"
							color="esmeralda.100"
							fontSize={getFont("2xl")}
							fontWeight="bold"
							aria-label="Para Gestores"
						>
							Para Gestores
						</Text>{' '}
						também é possível cadastrar os planos de educação e realizar as
						etapas necessárias de entrega ao MEC através da plataforma.
					</Text>
				</Box>
			</Box>
		</FullPageLayout>
	);
});
