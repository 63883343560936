import {
	Box,
	Stack,
	Link,
	Image,
	IconButton,
	Icon,
	useBreakpointValue,
} from "@chakra-ui/react";
import { useSize } from "Context/letterSizeHook";
import GovbrLogo from "modules/common/assets/images/govbr-logo.svg";
import {
	IoMdContrast,
	IoIosAddCircleOutline,
	IoIosRemoveCircleOutline,
	IoMdSync,
} from "react-icons/io";
import { FullPageLayout, DrawerMenu } from "..";
import { useContext } from "react";
import { ThemeContext } from "theme";
import { highContrastTheme } from "theme/high-contrast-theme";

export const Govbar = () => {
	return (
		<FullPageLayout boxShadow="xs">
			<Box py={10} display="flex" justifyContent="space-between">
				<Image src={GovbrLogo} alt="logotipo do governo brasileiro" />

				<NavigationMenu />
			</Box>
		</FullPageLayout>
	);
};

const NavigationMenu = () => {
	const mobileScreen = useBreakpointValue({ base: true, lg: false });

	if (mobileScreen) {
		return (
			<DrawerMenu placement="right">
				<MenuButtons direction="column" />
			</DrawerMenu>
		);
	}

	return <MenuButtons direction="row" />;
};

const MenuButtons = ({ direction }) => {
	const { setSizeBack, setSizeMinus, setSizePlus } = useSize()
	const { isHighContrast, setTheme, resetTheme } = useContext(ThemeContext)

	//TRECHO DE CODIGO COMENTADO 
	//POIS SERA UTILIZADO EM BREVE

	// const isAuthenticated = useHasSession()

	// const handleLoginGovbr = async () => {
	// 	const url = await govbrAuth()
	// 	window.location.href = url
	// }

	const handleHighContrast = () => {
		if (!isHighContrast) {
			setTheme(highContrastTheme);
		} else {
			resetTheme();
		}
	}

	const iconButtonColor = isHighContrast ? '#F4F4F4' : 'blue.300'

	return (
		<Stack direction={direction} alignItems="center" spacing={2.5}>
			<Link
				href="https://www.gov.br/pt-br/orgaos-do-governo"
				isExternal
				fontWeight="medium"
				_hover={{ textDecoration: "none" }}
				aria-label="Órgaõs do Governo"
			>
				Órgaõs do Governo
			</Link>
			<Link
				href="https://www.gov.br/acessoainformacao/pt-br"
				isExternal
				fontWeight="medium"
				_hover={{ textDecoration: "none" }}
				aria-label="Acesso à Informação"
			>
				Acesso à Informação
			</Link>
			<Link
				href="http://www4.planalto.gov.br/legislacao/"
				isExternal
				fontWeight="medium"
				_hover={{ textDecoration: "none" }}
				aria-label="Legislação"
			>
				Legislação
			</Link>
			<Link
				href="https://www.gov.br/governodigital/pt-br/acessibilidade-digital"
				isExternal
				fontWeight="medium"
				_hover={{ textDecoration: "none" }}
				aria-label="Acessibilidade"
			>
				Acessibilidade
			</Link>
			<IconButton
				variant="ghost"
				_hover={{ bg: "none" }}
				icon={<Icon as={IoMdContrast} />}
				color={iconButtonColor}
				cursor="pointer"
				aria-label="Liga e desliga alto contraste"
				onClick={handleHighContrast}
			/>
			<IconButton
				variant="ghost"
				_hover={{ bg: "none" }}
				icon={<Icon as={IoIosAddCircleOutline} />}
				color={iconButtonColor}
				cursor="pointer"
				aria-label="Aumenta Letra"
				onClick={setSizePlus}
			/>
			<IconButton
				variant="ghost"
				_hover={{ bg: "none" }}
				icon={<Icon as={IoMdSync} />}
				color={iconButtonColor}
				cursor="pointer"
				aria-label="Volta Letra Padrão"
				onClick={setSizeBack}
			/>
			<IconButton
				variant="ghost"
				_hover={{ bg: "none" }}
				icon={<Icon as={IoIosRemoveCircleOutline} />}
				color={iconButtonColor}
				cursor="pointer"
				aria-label="Diminui Letra"
				onClick={setSizeMinus}
			/>
			{/* TRECHO DE CODIGO COMENTADO 
					POIS SERA UTILIZADO EM BREVE

			{!isAuthenticated && (
				<Button
					bg="#1351B4"
					color="white"
					borderRadius="3xl"
					fontWeight="medium"
					_hover={{ bg: "#1351B4", color: "white" }}
					aria-label="Entrar"
					onClick={handleLoginGovbr}
				>
					Entrar
				</Button>
			)} */}
		</Stack>
	);
};
