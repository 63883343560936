import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Center, CircularProgress } from '@chakra-ui/react'
import { Tokens } from "config/tokens"

import { authenticate } from "./usecases"
import { getUserInfo } from "modules/user/usecases"
import { useSessionHandler } from "modules/common/hooks"


const LoginRedirect = () => {
	const navigate = useNavigate()
	const { setCurrentUser } = useSessionHandler()

	const [searchParams] = useSearchParams()
	const code = searchParams.get('code')

	useEffect(() => {
		const handleAuthenticate = async () => {
			const tokens = await authenticate(code)
			if (tokens) {
				Tokens.set(tokens)
				const user = await getUserInfo()
				if (user) setCurrentUser(user)
				navigate('/home', { replace: true })
			}
		}
		if (code) {
			handleAuthenticate()
		}
	}, [code, navigate, setCurrentUser])

	return (
		<Center height="100vh">
			<CircularProgress isIndeterminate color='esmeralda.100' />
		</Center>
	)
}

export default LoginRedirect