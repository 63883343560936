import { useContext, useState, useCallback } from "react";
import { createContext } from "react";
import { sizeLetter } from "../../theme/letter.js";

const SizeContext = createContext({
	size: 0,
	setSizePlus: () => {},
	setSizeMinus: () => {},
	setSizeBack: () => {},
	getFont: () => {},
});

export function useSize() {
	return useContext(SizeContext);
}

export const SizeProvider = ({ children }) => {
	const [size, setSize] = useState(0);

	const handleSizePlusChange = useCallback(async () => {
		//size limit 2
		if (size >= 3) {
			setSize(2);
		} else {
			setSize(size + 1);
		}
	}, [size]);
	const handleSizeMinusChange = useCallback(async () => {
		if (size <= 1) {
			setSize(0);
		} else {
			setSize(size - 1);
		}
	}, [size]);
	const handleSizeBackChange = useCallback(async () => {
		setSize(0);
	}, []);
	function letterChange(data) {
		for (var item in sizeLetter) {
			var newS = sizeLetter[data][1] + size;
			if (sizeLetter[item][1] === newS) {
				return sizeLetter[item][0];
			}
		}
	}

	return (
		<SizeContext.Provider
			value={{
				size: size,
				setSizePlus: handleSizePlusChange,
				setSizeMinus: handleSizeMinusChange,
				setSizeBack: handleSizeBackChange,
				getFont: letterChange,
			}}
		>
			{children}
		</SizeContext.Provider>
	);
};
